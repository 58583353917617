import { getWBToken } from '@/api/login'
import defAva from '@/assets/images/profile.jpg'
import { getWebSocket } from '@/utils/websocket-client';



const url = 'ws://iapi.target-gd.com/spring-ws';

const useSocketStore = defineStore(
  'socket',
  {
    state: () => ({
      noticeMsg: ''
    }),
    actions: {
      initWebsocket() {
        getWBToken().then(res => {
          const { data: { token } } = res;
          const ws = getWebSocket();
          ws.open(`${url}?token=${token}`);
          ws.onmessage = ({ data }) => {
            if (data) {
              const resData = JSON.parse(data);
              if (resData.msg != 'pong') {
                console.log('收到推送消息')
                console.log(resData)
                this.noticeMsg = resData;
              }
            }
          };
        })
      },
    }
  })

export default useSocketStore
